<template>
  <div class="container w-sm-100 w-xs-100 px-5">
    <div class="row">
      <div class="col-xs-12 col-sm-offset-1 col-sm-10 col-md-offset-2 col-md-8 col-lg-offset-3 col-lg-6">
        <h3 class="mt-2">Fill in all your details</h3>
        <small class="text-error extra-small">* All fields below are required</small>
        <hr>

        <div class="sender-detail-form">
          <div class="row">
            <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 mb-3">
              <label for="sender-first-and-middle-name" class="control-label"> Full legal first and middle
                name</label>
              <div class="input-group">
                <input type="text" id="sender-first-and-middle-name" name="sender-first-and-middle-name"
                       class="form-control" v-model="senderFirstAndMiddleName" :readonly="KYCStatus">

              </div>
              <small v-if="validationSenderFirstAndMiddleNameError" class="text-error">Please fill in your
                first & middle
                name</small>

            </div>
            <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 mb-3">
              <label for="sender-last-name" class="control-label"> Full legal last name</label>
              <div class="input-group">
                <input type="text" id="sender-last-name" name="sender-last-name" class="form-control"
                       v-model="senderLastName" :readonly="KYCStatus">
              </div>
              <small v-if="validationSenderLastNameError" class="text-error">Please fill in your last
                name</small>
            </div>

          </div>
          <div class="row">
            <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 mb-3">
              <label for="sender-date-of-birth" class="control-label"> Date of Birth</label>
              <div class="input-group">
                <input type="date" id="sender-date-of-birth" name="sender-date-of-birth"
                       class="form-control" :max="maxDate" v-model="senderDateOfBirth">
              </div>
              <small v-if="validationSenderDateOfBirthError" class="text-error">Please enter your date of
                birth</small>
              <small v-else-if="validationSenderDateOfBirthMinimumAgeError" class="text-error">Your date of birth should
                be before {{ maxDate }}</small>
            </div>
            <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 mb-3">
              <label for="sender-phone" class="control-label">Phone</label>
              <div class="input-group">
                <!--                                <input type="tel" id="sender-phone" name="sender-phone" class="form-control"-->
                <!--                                       v-model="senderPhone">-->
                <vue-tel-input id="sender-phone" v-model="senderPhone" class=""
                               v-bind="phoneValidation" @validate="phoneValidationMethod"></vue-tel-input>
              </div>
              <small v-if="validationSenderPhoneError" class="text-error">Please enter a valid contact
                number (prefix with +CountryCode)</small>
            </div>
            <!--                        <div>Sender Phone : {{senderPhone}}</div>-->
          </div>

          <h4 class="mt-4">Your Address</h4>
          <hr>
          <div class="row">
            <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12 mb-3">
              <label for="sender-address" class="control-label">Address</label>
              <div class="input-group">
                <input type="text" id="sender-address" name="sender-address" class="form-control"
                       v-model="senderAddress">
              </div>
              <small v-if="validationSenderAddressError" class="text-error">Please enter your
                address</small>
            </div>
          </div>
          <div class="row">

            <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 mb-3">
              <label for="sender-city" class="control-label">City</label>
              <div class="input-group">
                <input type="text" id="sender-city" name="sender-city" class="form-control"
                       v-model="senderCity">
              </div>
              <small v-if="validationSenderCityError" class="text-error">Please enter a city</small>
            </div>
            <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 mb-3">
              <label for="sender-postal-code" class="control-label">Postal Code</label>
              <div class="input-group">
                <input type="text" id="sender-postal-code" name="sender-postal-code"
                       class="form-control" v-model="senderPostalCode">
              </div>
              <small v-if="validationSenderPostalCodeError" class="text-error">Please enter your postal
                code</small>
            </div>

          </div>

          <div class="row">
            <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 mb-3">
              <label for="sender-province" class="control-label">Province/State</label>
              <div class="input-group">
                <input type="text" id="sender-province" name="sender-province" class="form-control"
                       v-model="senderProvince">
              </div>
              <!--                            <small v-if="validationSenderProvinceError" class="text-error">Please enter your-->
              <!--                                province/state</small>-->
            </div>

            <div class="col-md-6 col-xs-12 col-sm-6 col-lg-6 mb-3">
              <label for="sender-country" class="control-label">Country</label>
              <div class="input-group">
                <!--                                <select id="sender-country" name="sender-country" class="" v-if="countryList" v-model="senderCountry">-->
                <!--                                    <option v-for="item in countryList" :key="item.name" :value="item.countryCode">-->
                <!--                                         {{item.name}} {{item.emoji}}-->
                <!--                                    </option>-->
                <!--                                </select>-->

                <v-select :options="countryList"
                          :clearable="false"
                          id="sender-country"
                          label="name"
                          class="w-100"
                          v-model="senderCountry"
                          :reduce="item => item.countryCode">
                  <template v-slot:option="item">
                    <country-flag :country="item.countryCode.toLowerCase()"
                                  size="small"></country-flag>
                    {{ item.name }}
                  </template>
                </v-select>
              </div>
              <small v-if="validationSenderCountryError" class="text-error">Please select a
                country</small>
            </div>
          </div>
          <br>
          <div class="">
            <button class="btn btn-block btn-primary" @click="submitSenderDetailsForm()">Continue</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import {mapActions, mapGetters} from 'vuex'
import {VueTelInput} from 'vue-tel-input';
// import 'vue-tel-input/dist/vue-tel-input.css'
import {countries} from 'countries-list';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import CountryFlag from 'vue-country-flag'

Vue.use(VueTelInput);


export default {
  name: 'sender-details',
  components: {
    VueTelInput,
    vSelect,
    CountryFlag
  },
  data() {
    return {
      mode: process.env.VUE_APP_STAGING_ENV ? null : "production",
      maxDate: "",
      senderFirstAndMiddleName: "",
      senderLastName: "",
      senderDateOfBirth: "",
      senderPhone: "",
      senderCountry: "",
      senderCity: "",
      senderAddress: "",
      senderPostalCode: "",
      senderProvince: "",

      senderDetailsExist: false,

      validationSenderFirstAndMiddleNameError: false,
      validationSenderLastNameError: false,
      validationSenderDateOfBirthError: false,
      validationSenderPhoneError: false,
      validationSenderCountryError: false,
      validationSenderCityError: false,
      validationSenderAddressError: false,
      validationSenderPostalCodeError: false,
      validationSenderDateOfBirthMinimumAgeError: false,

      phoneValidationError: false,
      // validationSenderProvinceError: false,

      // phoneValidation: {
      //     mode: 'auto',
      //     dropdownOptions: {
      //         showDialCodeInList: true,
      //         showDialCodeInSelection: false,
      //         showFlags: true,
      //     },
      //     inputOptions: {
      //         styleClasses: {
      //             'border': 'none',
      //             'border-radius': '0 2px 2px 0',
      //             'width': '60% !important',
      //             'outline': 'none',
      //             'padding-left': '7px',
      //         },
      //         autocomplete : 'on'
      //     },
      //     validCharactersOnly: true,
      // },

      phoneValidation: {
        mode: 'international',
        inputOptions: {
          showDialCode: false,
        },
        placeholder: "Enter a phone number",
        disabledFetchingCountry: true,
        enabledCountryCode: false,
        validCharactersOnly: true,
        defaultCountry: this.$store.getters.userDetailsGetter && this.$store.getters.userDetailsGetter.profile.senderCountry
            ? this.$store.getters.userDetailsGetter.profile.senderCountry : ""
      },

      countryList: Object.entries(countries).map((item) => {
        return Object.assign(item[1], {countryCode: item[0]})
      }).sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }).filter(item => ["AL", "BS", "BB", "BW", "KH", "IS", "JM", "MU", "MN", "MM", "NI", "PK", "PA", "SY", "UG", "YE", "ZW", "IR", "KP", "GS", "NG","RU","BY"].indexOf(item.countryCode) < 0),

      debugConsole: false,

      KYCStatus: false,
    }
  },
  watch: {
    $route() {

    }
  },
  computed: {
    ...mapGetters(["userDetailsGetter"])
  },
  metaInfo: {
    title: 'TransCrypt - Sender Details | Send Money Faster with Cryptocurrency',
  },
  mounted() {
    var vm = this;
    if (this.debugConsole) console.debug("Sender Details Mounted");

    var eighteenYearsAgo = new Date();
    eighteenYearsAgo = eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

    this.maxDate = new Date(eighteenYearsAgo).toISOString().split('T')[0];
    if (this.debugConsole) console.log("Eighteen Years Ago", new Date(eighteenYearsAgo).toISOString().split('T')[0]);


    //for stepper
    let target = "sender-info";
    this.$store.commit('UPDATE_STEPPER', target);


    this.senderFirstAndMiddleName = this.userDetailsGetter.profile && (this.userDetailsGetter.profile.senderFirstAndMiddleName === null ? "" : this.userDetailsGetter.profile.senderFirstAndMiddleName)
    this.senderLastName = this.userDetailsGetter.profile && (this.userDetailsGetter.profile.senderLastName === null ? "" : this.userDetailsGetter.profile.senderLastName)
    this.senderDateOfBirth = this.userDetailsGetter.profile && (this.userDetailsGetter.profile.senderDateOfBirth === null ? "" : this.userDetailsGetter.profile.senderDateOfBirth)
    this.senderPhone = this.userDetailsGetter.profile && (this.userDetailsGetter.profile.senderPhone === null ? "" : this.userDetailsGetter.profile.senderPhone)
    this.senderCountry = this.userDetailsGetter.profile && (this.userDetailsGetter.profile.senderCountry === null ? "UK" : this.userDetailsGetter.profile.senderCountry)
    this.senderCity = this.userDetailsGetter.profile && (this.userDetailsGetter.profile.senderCity === null ? "" : this.userDetailsGetter.profile.senderCity)
    this.senderAddress = this.userDetailsGetter.profile && (this.userDetailsGetter.profile.senderAddress === null ? "" : this.userDetailsGetter.profile.senderAddress)
    this.senderPostalCode = this.userDetailsGetter.profile && (this.userDetailsGetter.profile.senderPostalCode === null ? "" : this.userDetailsGetter.profile.senderPostalCode)
    this.senderProvince = this.userDetailsGetter.profile && (this.userDetailsGetter.profile.senderProvince === null ? "" : this.userDetailsGetter.profile.senderProvince)

    //CHECK SENDER KYC STATUS
    this.KYCStatus = this.userDetailsGetter && this.userDetailsGetter.profile.senderKYCStatus === 'pass' ? true : false;


  },

  methods: {
    ...mapActions(["submitUserProfile", "getSenderDetails", "submitSenderDetails"]),
    phoneValidationMethod({isValid}) {
      if (this.debugConsole) console.log("Is Valid", isValid);
      if (isValid) {
        this.phoneValidationError = false;
      } else {
        this.phoneValidationError = true;
      }

    },
    submitSenderDetailsForm() {

      //if sender detail exist
      //can straight continue to next page


      if (this.senderDetailsExist) {
        this.$router.push('/transfer/recipient-details');
        this.$store.commit('UPDATE_STEPPER', 'recipient-info');
      }
          //if sender detail does not exist
      //need to send the details to backend before continuing to next page
      else {
        //validation
        if (!this.senderFirstAndMiddleName) {
          this.validationSenderFirstAndMiddleNameError = true;
        } else {
          this.validationSenderFirstAndMiddleNameError = false;
        }

        if (!this.senderLastName) {
          this.validationSenderLastNameError = true;
        } else {
          this.validationSenderLastNameError = false;
        }

        if (!this.senderDateOfBirth) {
          this.validationSenderDateOfBirthError = true;
          this.validationSenderDateOfBirthMinimumAgeError = false;
        } else if (this.senderDateOfBirth && this.senderDateOfBirth > this.maxDate) {
          this.validationSenderDateOfBirthMinimumAgeError = true;
          this.validationSenderDateOfBirthError = false;
        } else {
          this.validationSenderDateOfBirthError = false;
          this.validationSenderDateOfBirthMinimumAgeError = false;
        }

        if (!this.senderPhone || this.senderPhone && this.senderPhone.indexOf('+') < 0 || this.senderPhone && this.phoneValidationError) {
          this.validationSenderPhoneError = true;

        } else {
          this.validationSenderPhoneError = false;
        }

        if (!this.senderCountry) {
          this.validationSenderCountryError = true;
        } else {
          this.validationSenderCountryError = false;
        }

        if (!this.senderCity) {
          this.validationSenderCityError = true;
        } else {
          this.validationSenderCityError = false;
        }

        if (!this.senderAddress) {
          this.validationSenderAddressError = true;
        } else {
          this.validationSenderAddressError = false;
        }

        if (!this.senderPostalCode) {
          this.validationSenderPostalCodeError = true;
        } else {
          this.validationSenderPostalCodeError = false;
        }

        // if (!this.senderProvince) {
        //     this.validationSenderProvinceError = true;
        // } else {
        //     this.validationSenderProvinceError = false;
        // }

        if (this.validationSenderFirstAndMiddleNameError || this.validationSenderLastNameError || this.validationSenderPhoneError || this.validationSenderDateOfBirthError || this.validationSenderDateOfBirthMinimumAgeError ||
            this.validationSenderCountryError || this.validationSenderCityError || this.validationSenderAddressError || this.validationSenderPostalCodeError) {
          return;
        }


        let payload = {};
        payload.first_name = this.senderFirstAndMiddleName;
        payload.last_name = this.senderLastName;
        payload.date_of_birth = this.senderDateOfBirth;
        payload.phone = this.senderPhone;
        payload.address_country = this.senderCountry;
        payload.address_city = this.senderCity;
        payload.address_lines = this.senderAddress;
        payload.postal_code = this.senderPostalCode;
        payload.address_state = this.senderProvince || "";
        if (this.debugConsole) console.log("sender Details data", payload);

        this.submitSenderDetails(payload).then(result => {
          if (this.debugConsole) console.log("Submit Sender Details Result in Transfer Details comp", result);

          let payload = {}
          payload.senderFirstAndMiddleName = result.data && result.data.user_first;
          payload.senderLastName = result.data && result.data.user_last;
          payload.senderDateOfBirth = this.senderDateOfBirth;
          payload.senderPhone = this.senderPhone;
          payload.senderCountry = result.data && result.data.address_country;
          payload.senderCity = result.data && result.data.address_city;
          payload.senderAddress = result.data && result.data.address_lines;
          payload.senderPostalCode = result.data && result.data.postal_code;
          payload.senderProvince = result.data && result.data.address_state;

          this.$store.commit("UPDATE_USER_PROFILE", payload);
          this.$router.push('/transfer/recipient-details');
          this.$store.commit('UPDATE_STEPPER', 'recipient-info');
        }).catch(err => {
          // if (this.debugConsole)
          console.log("Submit Sender Details Result Error in transfer details comp", err.response);

          if (err.response.data.errors) {
            if (err.response.data.errors.find(el => el.message === 'invalid' && el.path === "phone")) {
              this.validationSenderPhoneError = true;
            }
          }
        })


      }


    }
  }
}
</script>
<style scoped>


</style>
